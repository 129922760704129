<!-- //
角色编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <PageHeader :show-icon="showIcon"
                :show-button="true"
                :show-confirm="false"
                :button-list="buttonList"
                @publishClick="handleSave"
                @saveClick="handleCancel">
    </PageHeader>

    <div class="layout-result">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p48">

        <a-form-model-item prop="roleName">
          <span slot="label" class="form-label">角色名称</span>
          <a-input v-model="form.roleName" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="menus">
          <span slot="label" class="form-label">添加权限</span>
          <a-tree
              v-model="form.menus"
              checkable
              :auto-expand-parent="autoExpandParent"
              :selected-keys="selectedKeys"
              :tree-data="menuList"
              :replaceFields="{children:'children', title:'menuName', key:'id'}"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>

  import * as RoleApi from '@/api/set/role.api'
  import * as MenuApi from '@/api/sys/menu.api'

  export default {
    name: 'RoleEditComp',
    components: {},
    dictTypes: [],
    data() {
      return {
        showIcon: false, // 从列表进来true，菜单进来false
        // mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
        buttonList: [
          {label: '确定', type: "primary"},
          {label: '取消', type: ""}
        ],
        menuList: [],
        expandedKeys: [],
        autoExpandParent: true,
        selectedKeys: [],
        form: {
          roleId: this.id,
          roleName: null,
          menus: [],
        },
        rules: {
          roleName: [{required: true, message: '角色名称不能为空', trigger: 'blur'}],
          menus: [{required: true, message: '权限不能为空', trigger: 'change'}],
        },
      };
    },
    props: {
      id: String,
      mode: String,// mode=[1(新增）|2（编辑）|3（详情）]
    },
    computed: {
      isEditMode() {
        return this.mode == '2'
      },
    },
    created() {
      this.showIcon = this.mode ? true : false
      this.getInit()
      if (this.isEditMode) {
        this.getDetail()
      }
    },
    methods: {
      getInit() {
        MenuApi.selectMenuList(this.$store.getters.businessType).then(data => {
          console.log("=========>", data)
          data.forEach(item => item.menuName = item.meta.title)
          this.menuList = this.$util.listToTree(data)
        })
      },

      getDetail() {
        RoleApi.get(this.form.roleId).then(data => {
          console.log("================>", data)
          let f = data || {};
          f.menus = f.menus || [];
          this.form = f;
        })
      },

      handleSave() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            // console.log("================>", this.form)
            RoleApi.save(this.form).then(data => {
              this.$modal.msg("保存成功")
              this.$router.replace({name: 'RoleList', query: {}})
            })
          }
        })
      },

      handleCancel() {
        this.$router.back()
      },

      // onExpand(expandedKeys) {
      //   console.log('onExpand', expandedKeys);
      //   // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      //   // or, you can remove all expanded children keys.
      //   this.expandedKeys = expandedKeys;
      //   this.autoExpandParent = false;
      // },
      // onCheck(checkedKeys) {
      //   console.log('onCheck', checkedKeys);
      //   this.form.menus = checkedKeys;
      // },
      // onSelect(selectedKeys, info) {
      //   console.log('onSelect', info);
      //   this.selectedKeys = selectedKeys;
      // },
    }
  }
</script>
<style lang="less" scoped>
  .form-label {
    font-size: 16px;
    font-weight: 600;
  }
</style>
