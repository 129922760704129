<!-- //
角色管理-编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <EditComp :mode="2" :id="id"></EditComp>
</template>
<script>
  import EditComp from './editComp'

  export default {
    name: 'RoleEdit',
    components: {EditComp},
    data() {
      return {
        id: null,
        mode: null
      }
    },
    created() {
      this.id = this.$route.query.id
      this.mode = this.$route.query.mode
    },
  }
</script>
